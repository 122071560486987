import { FC, useCallback, useEffect, useMemo } from "react";
import cx from "classnames";
import styles from "./iframe-popup.module.scss";
import Modal from "../../common-components/modal";
import Iframe from "../../common-components/iframe";
import { CLOSE_POPUP_EVENT } from "../../../utils/popups";
import { useIsMobile } from "../../../utils/useIsMobile";
import { POPUP_DEFAULT_WIDTH, POPUP_DEFAULT_HEIGHT } from "../../../types/ui";
import { IFramePopupData, CloseButtonType } from "../../../types/popup";
import { hidePopup } from "../../../hooks/usePopups";
import ReactModal from "react-modal";

interface IIframePopup {
  isOpen: boolean;
  config: IFramePopupData;
  isSmartphone: boolean;
}

const IframePopup: FC<IIframePopup> = ({ isOpen, config, isSmartphone }) => {
  const isMobile = useIsMobile(768, isSmartphone);
  const popupWidth = config?.width || POPUP_DEFAULT_WIDTH;
  const popupHeight = config?.height || POPUP_DEFAULT_HEIGHT;

  const heightForContent = useMemo(() => {
    if (isMobile) {
      return config?.mobileHeight ? config?.mobileHeight : "100%";
    } else {
      return popupHeight;
    }
  }, [config?.mobileHeight, isMobile, popupHeight]);

  const handleClose = useCallback((e: any) => {
    e?.stopPropagation();
    hidePopup();
  }, []);

  const modalStyles: ReactModal.Styles = {
    overlay: {
      backgroundColor: "rgba(24, 24, 24, 0.8)",
    },
    content: {
      width: isMobile ? config?.mobileWidth || "100%" : popupWidth,
      height: heightForContent,
      maxHeight: config.maxHeight ?? (isMobile ? "100vh" : "80vh"),
      maxWidth: config.maxWidth,
    },
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("message", (event) => {
        if (event.data?.type === CLOSE_POPUP_EVENT) {
          handleClose(undefined);
        }
        if (config.onMessageCallback) {
          config.onMessageCallback(event.data);
        }
      });
    }
  }, [handleClose, config.onMessageCallback, config]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      style={modalStyles}
      closeButtonClass={cx(styles.closeButton, {
        [styles.abovePopup]: config?.closeButtonType === CloseButtonType.EXTERNAL,
      })}
      closeButtonType={config?.closeButtonType}
    >
      <Iframe url={config?.url} allowScrolling={config.allowScrolling} allowFullScreen={config.allowFullScreen} />
    </Modal>
  );
};

export default IframePopup;
