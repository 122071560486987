"use client";

import { useCallback, useEffect, useState } from "react";
import { LS_KEYS } from "../types/constants";

const LIKES_CHANGED_EVENT = "rml-likesChanged";
interface LikesData {
  vehicles: string[];
  date: string;
}

const MAX_FAVORITES_STORAGE_TIME = 60 * 24 * 3600 * 1000; // keep favorites 60 days

function loadLikes(): string[] {
  const likes = window.localStorage.getItem(LS_KEYS.likes);
  if (likes) {
    const result: LikesData = JSON.parse(likes);
    if (!result.date) {
      window.localStorage.removeItem(LS_KEYS.likes);
      return [];
    }
    const date = new Date(result.date);
    if (date.getTime() + MAX_FAVORITES_STORAGE_TIME < new Date().getTime()) {
      window.localStorage.removeItem(LS_KEYS.likes);
      return [];
    }

    return result.vehicles || [];
  }

  return [];
}

export function useLikes(): number {
  const [likedVehicles, setLikedVehicles] = useState(0);

  const vehicleChangedCallback = useCallback(() => {
    const likeVehicles: string[] = loadLikes();
    setLikedVehicles(likeVehicles.length);
  }, []);

  useEffect(() => {
    const likeVehicles: string[] = loadLikes();
    if (likeVehicles.length) {
      setLikedVehicles(likeVehicles.length);
    }

    window.addEventListener(LIKES_CHANGED_EVENT, vehicleChangedCallback);

    return () => window.removeEventListener(LIKES_CHANGED_EVENT, vehicleChangedCallback);
  }, [vehicleChangedCallback]);

  return likedVehicles;
}

export function useLikedVehicles(): string[] {
  const [likedVehicles, setLikedVehicles] = useState<string[]>([]);

  const vehicleChangedCallback = useCallback(() => {
    const likeVehicles = loadLikes();
    setLikedVehicles(likeVehicles);
  }, []);

  useEffect(() => {
    const likeVehicles = loadLikes();
    if (likeVehicles.length) {
      setLikedVehicles(likeVehicles);
    }

    window.addEventListener(LIKES_CHANGED_EVENT, vehicleChangedCallback);

    return () => window.removeEventListener(LIKES_CHANGED_EVENT, vehicleChangedCallback);
  }, [vehicleChangedCallback]);
  return likedVehicles;
}

export function saveLikedVehicles(vehicleIds: string[]) {
  const likesData: LikesData = {
    vehicles: vehicleIds,
    date: new Date().toISOString(),
  };

  window.localStorage.setItem(LS_KEYS.likes, JSON.stringify(likesData));
  window.dispatchEvent(new CustomEvent(LIKES_CHANGED_EVENT, { bubbles: false }));
}

export function setLikedVehicle(vehicleId: string) {
  const likeVehicles = loadLikes();
  if (!likeVehicles.includes(vehicleId)) {
    likeVehicles.push(vehicleId);
  }

  saveLikedVehicles(likeVehicles);
}

export function deleteLikedVehicle(vehicleId: string) {
  const likeVehicles = loadLikes();
  const index = likeVehicles.indexOf(vehicleId);
  if (index !== -1) {
    likeVehicles.splice(index, 1);
    saveLikedVehicles(likeVehicles);
  }
}

export function deleteAllLikes() {
  window.localStorage.removeItem(LS_KEYS.likes);
  window.dispatchEvent(new CustomEvent(LIKES_CHANGED_EVENT, { bubbles: false }));
}
