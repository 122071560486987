"use client";

import { useCallback, useEffect, useState } from "react";
import { IFramePopupData, PopupType } from "../types/popup";

export enum PopupDataType {
  IFRAME = "iframe",
  NO_DATA = "",
}

export type PopupData = IFramePopupData;

export interface PopupsState {
  activePopup?: PopupType;
  popupData?: PopupData;
  popupDataType?: PopupDataType;
}

const POPUP_STATE_CHANGED_EVENT = "rml-popupsChanged";

let globalPopupState: PopupsState = {};

export function usePopupState(): PopupsState {
  const [popupState, setPopupState] = useState(globalPopupState);
  const popupsChangedCallback = useCallback(() => {
    setPopupState(globalPopupState);
  }, []);
  useEffect(() => {
    window.addEventListener(POPUP_STATE_CHANGED_EVENT, popupsChangedCallback);
    return () => window.removeEventListener(POPUP_STATE_CHANGED_EVENT, popupsChangedCallback);
  }, [popupsChangedCallback]);
  return popupState;
}

export function showPopup(popupType: PopupType, popupData?: PopupData) {
  let popupDataType: PopupDataType = PopupDataType.NO_DATA;
  if (popupType === PopupType.IFRAME) {
    popupDataType = PopupDataType.IFRAME;
  }
  globalPopupState = {
    popupData,
    popupDataType,
    activePopup: popupType,
  };

  window.dispatchEvent(new CustomEvent(POPUP_STATE_CHANGED_EVENT, { bubbles: false }));
}

export function hidePopup() {
  globalPopupState = {};
  window.dispatchEvent(new CustomEvent(POPUP_STATE_CHANGED_EVENT, { bubbles: false }));
}
