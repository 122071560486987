const FavoriteIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.80426 4.0466C1.06265 5.74675 1.06264 8.50324 2.80425 10.2034L3.7009 11.0787L3.70077 11.0788L10.0039 17.232L10.0041 17.2318L10.0048 17.2325L16.3117 11.0757L16.311 11.075L17.2063 10.201C18.9469 8.50185 18.9469 5.74701 17.2063 4.04787C15.4658 2.34873 12.6438 2.34873 10.9032 4.04787L10.0078 4.9219L9.11119 4.0466C7.36958 2.34645 4.54587 2.34645 2.80426 4.0466Z"
      fill="currentColor"
    />
    <path
      d="M2.80518 10.2014L2.28127 10.7381L2.80518 10.2014ZM2.80518 4.0495L3.32908 4.58618L2.80518 4.0495ZM10 17.225L9.47611 17.7617L10 18.2731L10.5239 17.7617L10 17.225ZM16.302 11.0731L16.8259 11.6097L17.3756 11.0731L16.8259 10.5364L16.302 11.0731ZM16.3012 11.0723L15.7773 10.5356L15.2276 11.0723L15.7773 11.609L16.3012 11.0723ZM17.1973 10.1975L16.6734 9.66084L17.1973 10.1975ZM17.1973 4.04926L16.6734 4.58594V4.58594L17.1973 4.04926ZM10.8992 4.04926L10.3752 3.51258L10.8992 4.04926ZM10.003 4.92406L9.47911 5.46074L10.003 5.97217L10.5269 5.46074L10.003 4.92406ZM9.10713 4.0495L8.58322 4.58618V4.58618L9.10713 4.0495ZM3.32908 9.66475C1.89031 8.26023 1.89031 5.9907 3.32908 4.58618L2.28127 3.51282C0.239576 5.50591 0.239576 8.74501 2.28127 10.7381L3.32908 9.66475ZM10.5239 16.6883L3.32908 9.66475L2.28127 10.7381L9.47611 17.7617L10.5239 16.6883ZM15.7781 10.5364L9.47611 16.6883L10.5239 17.7617L16.8259 11.6097L15.7781 10.5364ZM15.7773 11.609L15.778 11.6097L16.8259 10.5364L16.8251 10.5357L15.7773 11.609ZM16.6734 9.66084L15.7773 10.5356L16.8251 11.609L17.7212 10.7342L16.6734 9.66084ZM16.6734 4.58594C18.1112 5.98945 18.1112 8.25733 16.6734 9.66084L17.7212 10.7342C19.7619 8.74212 19.7619 5.50466 17.7212 3.51258L16.6734 4.58594ZM11.4231 4.58594C12.8709 3.17257 15.2256 3.17257 16.6734 4.58594L17.7212 3.51258C15.6907 1.53036 12.4058 1.53036 10.3752 3.51258L11.4231 4.58594ZM10.5269 5.46074L11.4231 4.58594L10.3752 3.51258L9.47911 4.38738L10.5269 5.46074ZM8.58322 4.58618L9.47911 5.46074L10.5269 4.38738L9.63103 3.51282L8.58322 4.58618ZM3.32908 4.58618C4.77795 3.17179 7.13435 3.17179 8.58322 4.58618L9.63103 3.51282C7.59943 1.52958 4.31287 1.52958 2.28127 3.51282L3.32908 4.58618Z"
      fill="currentColor"
    />
  </svg>
);

export default FavoriteIcon;
