const GoBackIcon = () => (
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.92118 6.75017L9.0088 2.05099L7.99104 0.949097L0.898438 7.50019L7.99105 14.0509L9.00878 12.9489L3.92128 8.25017L14.0038 8.25017V6.75017L3.92118 6.75017Z"
      fill="currentColor"
    />
  </svg>
);

export default GoBackIcon;
