import { FC } from "react";
import cx from "classnames";
import styles from "./header-actions.module.scss";
import HeaderFavorites from "./header-favorites";
import HeaderCompares from "./header-compares";
import HeaderButtonSearch from "./header-search-button";
import { Translations } from "../../../utils/translations";

interface IHeaderActions {
  className?: string;
  translations: Translations;
}

const HeaderActions: FC<IHeaderActions> = ({ className, translations }) => {
  return (
    <div className={cx(styles.actions, className)}>
      <HeaderButtonSearch className={cx(styles.actionsButton, styles.actionsItem, styles.search)} />
      <HeaderCompares className={styles.actionsItem} translations={translations} />
      <HeaderFavorites className={styles.actionsItem} translations={translations} />
    </div>
  );
};

export default HeaderActions;
