"use client";

import { useState, useEffect, useRef, useCallback, FC } from "react";
import cx from "classnames";
import styles from "./dropdown-secondary.module.scss";
import ArrowIcon from "../../icons/arrow-icon";
import { ANALYTICS_DEPARTMENTS, sendAnalyticsEvent } from "../../../utils/analitics";

export interface IDropdownSecondaryOption {
  value: any;
  text1: string;
  text2: string;
  active?: boolean;
}

interface IDropdownSecondary {
  options: IDropdownSecondaryOption[];
  className?: string;
  numbers?: boolean;
  disabledChange?: boolean;
  label?: string;
  centerMenu?: boolean;
}

const DropdownSecondary: FC<IDropdownSecondary> = ({
  options,
  className,
  numbers,
  disabledChange,
  label,
  centerMenu,
}) => {
  const containerRef = useRef<any>(null);
  const [isOpen, setIsOpen] = useState(false);
  let activeOpt = options.find((item) => item?.active);
  if (!activeOpt) {
    activeOpt = options[0];
  }
  const [activeOption, setActiveOption] = useState<IDropdownSecondaryOption>(activeOpt);

  const onClick = (item: IDropdownSecondaryOption) => {
    if (!disabledChange && !numbers) {
      setActiveOption(item);
    }
    setIsOpen(false);
  };

  const closeOnOutsideClick = useCallback(
    (e: Event) => {
      let conRef = containerRef.current;
      if (isOpen && conRef && !conRef.contains(e.target)) {
        setIsOpen(false);
      }
    },
    [isOpen],
  );

  useEffect(() => {
    window.addEventListener("click", (e) => closeOnOutsideClick(e));

    return () => {
      window.removeEventListener("click", (e) => closeOnOutsideClick(e));
    };
  }, [closeOnOutsideClick]);

  const onPhoneClick = useCallback((phone: string, text: string) => {
    let analyticDep = text.toLocaleLowerCase();
    if (analyticDep.endsWith(":")) {
      analyticDep = analyticDep.substring(0, analyticDep.length - 1);
    }
    const department = ANALYTICS_DEPARTMENTS.includes(analyticDep) ? analyticDep : "";
    sendAnalyticsEvent("asc_click_to_call", { comm_phone_number: phone, department });
  }, []);

  return (
    <ul
      className={cx(
        styles.list,
        {
          [styles.active]: isOpen,
          [styles.centerMenu]: centerMenu,
        },
        className,
      )}
      ref={containerRef}
    >
      <div
        className={cx(styles.activeOption, { [styles.label]: label })}
        onClick={() => setIsOpen(isOpen ? false : true)}
      >
        {label ? (
          <>
            <span className={styles.value}>{label}</span>
            <ArrowIcon />
          </>
        ) : (
          <>
            <span className={cx(styles.value, styles.text)}>{activeOption?.text1}</span>
            <span className={styles.text}>{activeOption?.text2}</span>
            <ArrowIcon />
          </>
        )}
      </div>
      {options.map((item, index) =>
        numbers ? (
          <a
            title={item.text1}
            href={`tel:${item.text2}`}
            className={cx(styles.phone, { [styles.active]: isOpen })}
            key={index}
            onClick={() => onPhoneClick(item.text2, item?.text1)}
          >
            <span className={styles.value}>{item?.text1}</span>
            <span className={styles.text}>{item?.text2}</span>
          </a>
        ) : (
          <div className={cx(styles.option, { [styles.active]: isOpen })} key={index} onClick={() => onClick(item)}>
            <span className={styles.value}>{item?.text1}</span>
            <span className={styles.text}>{item?.text2}</span>
          </div>
        ),
      )}
    </ul>
  );
};

export default DropdownSecondary;
