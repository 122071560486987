import { FC, useCallback } from "react";
import styles from "./header-desktop-slider.module.scss";
import cx from "classnames";
import { ModelCount } from "../../../types/ModelInfo";
import { usePathname } from "next/navigation";
import { LogoPosition } from "../../../types/site";
import { smartFormat } from "../../../utils/stringFormatter";
import { sendAnalyticsEvent } from "../../../utils/analitics";

interface IHeaderDesktopSlider {
  newModels: ModelCount[];
  urlFormat: string;
  parentMenuText: string;
  logoPosition: LogoPosition;
}

const HeaderDesktopSlider: FC<IHeaderDesktopSlider> = ({ newModels, urlFormat, parentMenuText, logoPosition }) => {
  const asPath = usePathname();
  const shopModels = newModels;

  const handleOnImageLoadError = (e: any) => {
    e.target.src = "/images/out-of-stock-left.png";
  };

  const onModelClick = useCallback(
    (text: string) => {
      sendAnalyticsEvent("asc_menu_interaction", {
        element_text: text,
        element_type: parentMenuText,
        event_action: "click",
        element_subtype: "nav_icon",
      });
    },
    [parentMenuText],
  );

  return (
    <div
      className={cx(styles.slider, {
        [styles.centerMenu]: logoPosition === LogoPosition.CENTER,
        [styles.lightBg]: false,
      })}
    >
      {shopModels.map((item: ModelCount, index: number) => (
        <a
          key={index}
          href={smartFormat(urlFormat, item)}
          className={styles.sliderLink}
          onClick={() => onModelClick(item.model)}
        >
          <img
            src={item.image || "/images/out-of-stock-left.png"}
            onError={handleOnImageLoadError}
            height={70}
            width={105}
            alt={item.model}
          />
          <div
            className={cx(styles.sliderTitle, {
              [styles.sliderTitleSelected]: asPath.replace("%20", " ") === smartFormat(urlFormat, item),
            })}
          >
            <p title={item.model}>{item.model}</p>
            <span>{item.count}</span>
          </div>
        </a>
      ))}
    </div>
  );
};

export default HeaderDesktopSlider;
