"use client";

import { FC, useCallback, useEffect, useMemo, useState } from "react";
import cx from "classnames";
import { DealerDepartmentBaseInfo, LogoPosition } from "../../../types/site";
import { CustomPhonesData } from "../../../types/ui";
import { formatPhone } from "../../../utils/stringFormatter";
import DropdownSecondary, { IDropdownSecondaryOption } from "./dropdown-secondary";
import { ANALYTICS_DEPARTMENTS, sendAnalyticsEvent } from "../../../utils/analitics";
import styles from "./header-phones.module.scss";

interface IHeaderPhones {
  departments: DealerDepartmentBaseInfo[];
  customPhonesData: CustomPhonesData;
  logoPosition: LogoPosition;
}

const HeaderPhones: FC<IHeaderPhones> = ({ departments, customPhonesData, logoPosition }) => {
  const [accessilibityStorage, setAccessibilityStorage] = useState<any>();

  const phones: IDropdownSecondaryOption[] = useMemo(() => {
    if (customPhonesData?.customPhones) {
      return customPhonesData.customPhones.map((item, index) => ({
        value: index,
        text1: `${item.title}:`,
        text2: formatPhone(item.phone),
      }));
    }
    return departments.map((item, index) => ({
      value: index,
      text1: `${item.title}:`,
      text2: formatPhone(item.phone),
    }));
  }, [customPhonesData.customPhones, departments]);

  const accessibilityCallback = useCallback(() => {
    const currentValue = window.localStorage.getItem("accessibility");
    if (currentValue) {
      setAccessibilityStorage(JSON.parse(window.localStorage.getItem("accessibility") || ""));
    }
  }, []);

  useEffect(() => {
    if (window.localStorage.getItem("accessibility")) {
      setAccessibilityStorage(JSON.parse(window.localStorage.getItem("accessibility") || ""));
    }

    window.addEventListener("storage", accessibilityCallback);

    return () => window.removeEventListener("storage", accessibilityCallback);
  }, [accessibilityCallback]);

  const onPhoneClick = useCallback((phone: string, text: string) => {
    let analyticDep = text.toLocaleLowerCase();
    if (analyticDep.endsWith(":")) {
      analyticDep = analyticDep.substring(0, analyticDep.length - 1);
    }
    const department = ANALYTICS_DEPARTMENTS.includes(analyticDep) ? analyticDep : "";
    sendAnalyticsEvent("asc_click_to_call", {
      comm_phone_number: phone,
      department,
    });
  }, []);

  return phones?.length > 1 ? (
    <DropdownSecondary
      options={phones}
      numbers
      disabledChange
      centerMenu={logoPosition === LogoPosition.CENTER}
      label={customPhonesData?.customPhonesCaption}
    />
  ) : (
    <div
      className={cx(styles.department, {
        [styles.departmentbiggerText]: accessilibityStorage?.biggerText,
      })}
    >
      {phones.map(({ text1, text2 }, index) => (
        <div className={styles.departmentContent} key={index}>
          <a
            title={`${text1} ${text2}`}
            href={`tel: ${text2}`}
            className={styles.departmentText}
            onClick={() => onPhoneClick(text2, text1)}
          >
            <span>{text1}</span>
            <span>{text2}</span>
          </a>
          <span className={styles.dot}></span>
        </div>
      ))}
    </div>
  );
};

export default HeaderPhones;
