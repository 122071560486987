"use client";

import SearchIcon from "../../icons/search-icon";
import { useCallback } from "react";
import { sendAnalyticsEvent } from "../../../utils/analitics";
import { showPopup } from "../../../hooks/usePopups";
import { PopupType } from "../../../types/popup";

interface IHeaderButtonSearch {
  className: string;
}

export default function HeaderButtonSearch({ className }: IHeaderButtonSearch) {
  const globalSearchHandler = useCallback(() => {
    sendAnalyticsEvent("asc_cta_interaction", {
      element_text: "global search",
      element_type: "header",
      element_title: "global search",
      event_action_result: "open",
      link_url: "",
    });
    showPopup(PopupType.GLOBAL_SEARCH);
  }, []);

  return (
    <button
      name={"globalSearchButton"}
      aria-label={"global search on site"}
      className={className}
      onClick={globalSearchHandler}
    >
      <SearchIcon />
    </button>
  );
}
