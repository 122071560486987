"use client";

import React, { useState, useEffect, useCallback, FC } from "react";
import cx from "classnames";
import Modal from "../modal";
import styles from "./styles.module.scss";
import RightArrowIcon from "../../icons/right-arrow-icon";
import RadioButton from "../radio-button";
import Button from "../button";
import GoBackIcon from "../../icons/go-back-icon";
import CloseIcon from "../../icons/close-icon";
import { LogoPosition, MainMenu, MenuItem } from "../../../types/site";
import { languages } from "../../../utils/constants";
import { changeLocale, getLanguage } from "../../../utils/localeUtils";
import { handleButtonAction } from "../../../utils/ui";
import { ButtonAction } from "../../../types/site";
import { sendAnalyticsEvent } from "../../../utils/analitics";
import { RequestContext } from "../../../types/ui";
import { getTranslationModule, Translations } from "../../../utils/translations";

interface IMenu {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  menu: MainMenu;
  logoPosition: LogoPosition;
  context: RequestContext;
  translations: Translations;
}

const menuStyles = {
  overlay: {
    backgroundColor: "transparent",
    backdropFilter: "none",
  },
  content: {
    width: "100%",
    height: "100%",
  },
};

const Menu: FC<IMenu> = ({ isOpen, setIsOpen, menu, context, logoPosition, translations }) => {
  const [currentMenuItem, setCurrentCategory] = useState<MenuItem | undefined>(undefined);
  const [translateXPos, setTranslateXPos] = useState<number>(100);
  const currentLanguge = getLanguage(context.locale);
  const t = getTranslationModule(translations, "mobile-header");

  const languageObject: MenuItem = {
    id: "language_menu",
    path: "",
    title: t("language"),
    submenu: [],
  };

  const headerMenu = menu.items || [];

  const handleClose = () => {
    setTranslateXPos(100);
    setTimeout(() => {
      setIsOpen(false);
    }, 200);
  };

  const handleMenuButton = useCallback((button: ButtonAction, topMenu: string) => {
    sendAnalyticsEvent("asc_menu_interaction", {
      element_text: button.title,
      element_type: topMenu,
      event_action: "click",
      element_subtype: "nav_button",
    });
    handleButtonAction(button);
  }, []);

  const changeModalPosition = (direction: string) => {
    direction === "left"
      ? setTranslateXPos((prevState) => prevState - 100)
      : setTranslateXPos((prevState) => prevState + 100);
  };

  useEffect(() => {
    isOpen &&
      setTimeout(() => {
        setTranslateXPos(0);
      });
  }, [isOpen]);

  const handleLinkClick = useCallback((text: string, topMenu: string) => {
    sendAnalyticsEvent("asc_menu_interaction", {
      element_text: text,
      element_type: topMenu,
      event_action: "click",
      element_subtype: "hyperlink",
    });
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={() => handleClose()} style={menuStyles} hideCloseButton={true}>
      <div className={styles.container} style={{ transform: `translateX(${translateXPos}%)` }}>
        <div className={styles.content}>
          <div className={styles.header}>
            <h2 className={styles.title}>Menu</h2>
            <button
              aria-label={"close menu"}
              name={"menuMobileCloseButton"}
              onClick={() => handleClose()}
              className={styles.closeBtn}
            >
              <CloseIcon />
            </button>
          </div>
          <ul>
            {headerMenu.map((menuItem) => (
              <li
                key={menuItem.id}
                className={styles.listItem}
                onClick={() => {
                  if (menuItem?.submenu?.length > 0) {
                    changeModalPosition("left");
                    setCurrentCategory(menuItem);
                  } else if (menuItem.path) {
                    handleLinkClick(menuItem.title, menuItem.title);
                    window.open(menuItem.path, "_self");
                  }
                }}
              >
                {menuItem?.submenu?.length > 0 ? (
                  <>
                    <span className={styles.listItemText}>{menuItem.title}</span>
                    <RightArrowIcon />
                  </>
                ) : (
                  <a href={menuItem.path}>
                    <span className={styles.listItemText} onClick={handleClose}>
                      {menuItem.title}
                    </span>
                  </a>
                )}
              </li>
            ))}
            <li
              className={styles.listItem}
              onClick={() => {
                changeModalPosition("left");
                setCurrentCategory(languageObject);
              }}
            >
              <span className={styles.listItemText}>{languageObject.title}</span>
              <RightArrowIcon />
            </li>
          </ul>
        </div>
        <div className={styles.content}>
          <div
            className={styles.goBack}
            onClick={() => {
              changeModalPosition("right");
              setCurrentCategory(undefined);
            }}
          >
            <GoBackIcon />
            <span className={styles.goBackText}>{t("all")}</span>
          </div>
          {currentMenuItem && (
            <>
              <h2 className={cx(styles.title, styles.mb60)}>{currentMenuItem.title}</h2>
              <ul>
                {currentMenuItem.id === languageObject.id
                  ? languages.map((language) => (
                      <li
                        key={language.value}
                        className={styles.listItem}
                        onClick={() => {
                          changeLocale(language.value);
                        }}
                      >
                        <span className={styles.listItemText} onClick={handleClose}>
                          {language.title}
                        </span>
                        <RadioButton
                          isChecked={currentLanguge === language.value ? true : false}
                          inversedColors={logoPosition === LogoPosition.CENTER}
                        />
                      </li>
                    ))
                  : currentMenuItem.submenu &&
                    currentMenuItem.submenu.map((subMenu, index) =>
                      subMenu.buttonMode ? (
                        <Button
                          key={index}
                          name={"subcategoryMenuButton"}
                          ariaLabel={subMenu.title}
                          type="secondary"
                          text={subMenu.title}
                          className={styles.subcategoryBtn}
                          onClick={() => handleMenuButton(subMenu, currentMenuItem.title)}
                        />
                      ) : (
                        <li
                          className={styles.listItem}
                          key={subMenu.id}
                          onClick={() => {
                            handleLinkClick(subMenu.title, subMenu.title);
                            window.open(subMenu.path, "_self");
                          }}
                        >
                          <a href={subMenu.path}>
                            <span className={styles.listItemText} onClick={handleClose}>
                              {subMenu.title}
                            </span>
                          </a>
                        </li>
                      ),
                    )}
              </ul>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default Menu;
