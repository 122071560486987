import { FC } from "react";
import cx from "classnames";
import styles from "./styles.module.scss";
import CheckedIcon from "../../icons/checked-icon";
import { IFormEventData, sendAnalyticsEvent } from "../../../utils/analitics";

interface IRadioButton {
  text?: string;
  className?: string;
  isChecked?: boolean;
  setIsChecked?: (value: boolean) => void;
  inversedColors?: boolean;
  formEventData?: IFormEventData;
}

const RadioButton: FC<IRadioButton> = ({ className, isChecked, setIsChecked, text, inversedColors, formEventData }) => {
  return (
    <label
      className={cx(styles.label, className, { [styles.checked]: isChecked, [styles.inversedColors]: inversedColors })}
    >
      <input
        name={"radio"}
        className={styles.radio}
        type="radio"
        checked={isChecked}
        onChange={() => setIsChecked && setIsChecked(!isChecked)}
        onBlur={() => sendAnalyticsEvent("asc_form_engagement", { comm_status: "engage", ...formEventData })}
      />
      <div className={styles.radioIcon}>
        <CheckedIcon />
      </div>
      {text && <span className={styles.text}>{text}</span>}
    </label>
  );
};

export default RadioButton;
