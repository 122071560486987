const RightArrowIcon = () => (
  <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.24041 1.14667L6.09375 6L1.24041 10.8533"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

export default RightArrowIcon;
