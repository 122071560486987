"use client";

import React, { FC } from "react";
import { PopupType } from "../../../types/popup";

import Cookie from "./cookie";
import IframePopup from "./iframe-popup";
import GlobalSearch from "./global-search";
import { RequestContext } from "../../../types/ui";
import { PopupDataType, usePopupState } from "../../../hooks/usePopups";
import { IDealerInfo, Search } from "../../../types/site";
import { Translations } from "../../../utils/translations";

interface IPopups {
  needCookiesBanner?: boolean;
  context: RequestContext;
  dealer: IDealerInfo;
  logoUrl: string;
  searchData: Search;
  siteId: string;
  translations: Translations;
}

const Popups: FC<IPopups> = ({ needCookiesBanner, context, dealer, logoUrl, searchData, siteId, translations }) => {
  const popupState = usePopupState();
  const activePopup = popupState.activePopup;
  const popupData = popupState.popupData;
  const popupDataType = popupState.popupDataType;
  const iframeData = popupDataType === PopupDataType.IFRAME ? popupData : undefined;

  return (
    <>
      {needCookiesBanner && (
        <Cookie
          isOpen={typeof window !== "undefined" && !window.localStorage.getItem("cookie")}
          translations={translations}
        />
      )}
      {activePopup === PopupType.IFRAME && (
        <IframePopup
          isOpen={activePopup === PopupType.IFRAME}
          config={iframeData!}
          isSmartphone={context.isSmartphone}
        />
      )}
      {activePopup === PopupType.GLOBAL_SEARCH && (
        <GlobalSearch
          isOpen={activePopup === PopupType.GLOBAL_SEARCH}
          dealer={dealer}
          context={context}
          logoUrl={logoUrl}
          searchData={searchData}
          siteId={siteId}
          translations={translations}
        />
      )}
    </>
  );
};

export default Popups;
