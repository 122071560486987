import { FC } from "react";
import cx from "classnames";
import styles from "./styles.module.scss";

interface IButton {
  className?: string;
  onClick?: (e?: any) => void;
  text: string;
  type?: string;
  submit?: boolean;
  icon?: React.ReactElement;
  disabled?: boolean;
  name?: string;
  ariaLabel?: string;
  style?: any;
  onMouseEnter?: (e?: any) => void;
  onMouseLeave?: (e?: any) => void;
}

const Button: FC<IButton> = ({
  className,
  onClick,
  text,
  type,
  submit,
  icon,
  disabled,
  name,
  ariaLabel,
  style,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <button
      className={cx(styles.button, className, {
        [styles.primary]: type === "primary",
        [styles.disabled]: disabled,
      })}
      onClick={onClick}
      type={submit ? "submit" : "button"}
      name={name}
      aria-label={ariaLabel}
      style={style}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {icon}
      <span>{text}</span>
    </button>
  );
};

export default Button;
