const CheckedIcon = () => (
  <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7304 0.31701C12.1076 0.720412 12.0864 1.35322 11.683 1.73043L5.058 9.73043C4.67363 10.0898 4.07649 10.0899 3.69209 9.7305L0.317091 6.6405C-0.0863533 6.26334 -0.107659 5.63054 0.269504 5.22709C0.646666 4.82365 1.27947 4.80234 1.68292 5.1795L4.37493 7.63101L10.317 0.269579C10.7204 -0.107628 11.3532 -0.0863925 11.7304 0.31701Z"
      fill="currentColor"
    />
  </svg>
);

export default CheckedIcon;
