const AccessibilityIcon = () => (
  <svg width="21" height="25" viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5073 5.4125C9.8736 5.4125 9.33135 5.18097 8.8805 4.71793C8.42888 4.25566 8.20307 3.69969 8.20307 3.05C8.20307 2.40031 8.42888 1.84394 8.8805 1.38089C9.33135 0.918631 9.8736 0.6875 10.5073 0.6875C11.1409 0.6875 11.6835 0.918631 12.1352 1.38089C12.586 1.84394 12.8114 2.40031 12.8114 3.05C12.8114 3.69969 12.586 4.25566 12.1352 4.71793C11.6835 5.18097 11.1409 5.4125 10.5073 5.4125ZM19.724 8.07031C18.8023 8.28687 17.8519 8.46879 16.8726 8.61605C15.8933 8.7641 14.9236 8.8775 13.9635 8.95625V23.1608C13.9635 23.4955 13.8533 23.7711 13.6329 23.9877C13.4117 24.2042 13.1379 24.3125 12.8114 24.3125C12.485 24.3125 12.2116 24.1991 11.9911 23.9723C11.7699 23.7463 11.6593 23.4659 11.6593 23.1313V17.225H9.35516V23.1608C9.35516 23.4955 9.24494 23.7711 9.02451 23.9877C8.80331 24.2042 8.52949 24.3125 8.20307 24.3125C7.87664 24.3125 7.60321 24.1991 7.38278 23.9723C7.16157 23.7463 7.05097 23.4659 7.05097 23.1313V8.95625C6.09089 8.8775 5.12121 8.7641 4.14194 8.61605C3.16266 8.46879 2.21218 8.28687 1.2905 8.07031C0.964077 7.99156 0.714456 7.80926 0.541642 7.52339C0.368828 7.23832 0.330425 6.92844 0.426433 6.59375C0.503239 6.25906 0.681046 6.00785 0.959852 5.84011C1.23789 5.67316 1.54012 5.62906 1.86655 5.70781C3.24906 6.02281 4.67497 6.24922 6.14427 6.38703C7.61281 6.52484 9.06714 6.59375 10.5073 6.59375C11.9474 6.59375 13.4017 6.52484 14.8702 6.38703C16.3395 6.24922 17.7654 6.02281 19.148 5.70781C19.4744 5.62906 19.772 5.67316 20.0408 5.84011C20.3097 6.00785 20.4921 6.25906 20.5881 6.59375C20.6649 6.92844 20.6219 7.23832 20.459 7.52339C20.2954 7.80926 20.0504 7.99156 19.724 8.07031Z"
      fill="currentColor"
    />
  </svg>
);

export default AccessibilityIcon;
