import React, { FC } from "react";
import ReactModal from "react-modal";
import cx from "classnames";
import CloseIcon from "../../icons/close-icon";
import styles from "./styles.module.scss";
import "./common.scss";
import { useIsMobile } from "../../../utils/useIsMobile";
import { CloseButtonType } from "../../../types/popup";

ReactModal.defaultStyles.overlay!.zIndex = "4";
ReactModal.defaultStyles.overlay!.display = "flex";
ReactModal.defaultStyles.overlay!.alignItems = "center";
ReactModal.defaultStyles.overlay!.justifyContent = "center";
ReactModal.defaultStyles.overlay!.cursor = "pointer";

if (typeof window !== "undefined") {
  ReactModal.setAppElement("body");
}

interface IModal {
  isOpen: boolean;
  onClose?: (e: React.UIEvent) => void;
  className?: string;
  children?: React.ReactNode;
  hideCloseButton?: boolean;
  closeButtonClass?: string;
  closeButtonType?: CloseButtonType;
  cookie?: boolean;
  style?: ReactModal.Styles;
}

const Modal: FC<IModal> = ({
  isOpen,
  onClose,
  className,
  children,
  hideCloseButton,
  cookie,
  style,
  closeButtonClass,
  closeButtonType,
}) => {
  const isMobile = useIsMobile(768, undefined);

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={cx(styles.modal, className)}
      ariaHideApp={false}
      style={style}
      bodyOpenClassName={cookie ? "ReactModal__Body--open" : "ReactModal__Body--open-overflow-hidden"}
    >
      {isMobile && closeButtonType === CloseButtonType.EXTERNAL && !hideCloseButton && (
        <div className={styles.header} />
      )}
      {!hideCloseButton && (
        <button
          aria-label={"close modal"}
          name={"modalCloseButton"}
          onClick={onClose}
          className={cx(styles.closeBtn, closeButtonClass)}
        >
          <CloseIcon />
        </button>
      )}
      {children}
    </ReactModal>
  );
};

export default Modal;
