"use client";

import cx from "classnames";
import styles from "./header-favorites.module.scss";
import { useLikes } from "../../../hooks/useLikes";
import FavoriteIcon from "../../icons/favorite-icon";
import { Translations } from "../../../utils/translations";
import { getLocaleText } from "../../../utils/translations";

interface IHeaderFavorites {
  className: string;
  translations: Translations;
}

export default function HeaderFavorites({ className, translations }: IHeaderFavorites) {
  const likesCount = useLikes();
  const title = getLocaleText(translations, "header-actions", "favorites");

  return (
    <a href={"/favorites"} title={`${title} (${likesCount})`} className={cx(className, styles.favorite)}>
      <FavoriteIcon />
      {likesCount > 0 && <span className={styles.favoriteCount}>{likesCount}</span>}
    </a>
  );
}
