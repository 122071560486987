"use client";

import { FC, useCallback, useEffect, useState } from "react";
import cx from "classnames";
import "simplebar-react/dist/simplebar.min.css";
import "./custom-scroll.css";
import dynamic from "next/dynamic";

const SimpleBarPure = dynamic(() => import("simplebar-react"), { ssr: false });

export enum $vSrollPostionValues {
  SMALL = "small",
  MEDIUM = "medium",
}
interface ICustomScroll {
  $vScrollPosition?: $vSrollPostionValues;
  children: React.ReactNode;
  id?: string;
  layoutScroll?: boolean;
}

const CustomScroll: FC<ICustomScroll> = ({ children, id, $vScrollPosition, layoutScroll }) => {
  const [height, setHeight] = useState<number>(0);

  const calculateContainerHeight = useCallback(() => {
    const header = document.querySelector("header") as HTMLElement;
    setHeight(header.offsetHeight);
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      calculateContainerHeight();

      window.addEventListener("resize", calculateContainerHeight);
      return () => window.removeEventListener("resize", calculateContainerHeight);
    }
  }, [calculateContainerHeight]);

  return (
    <div
      id={id}
      className={cx("simplebar-container", {
        "trackoffset-small": $vScrollPosition === $vSrollPostionValues.SMALL,
        "trackoffset-medium": $vScrollPosition === $vSrollPostionValues.MEDIUM,
      })}
    >
      <SimpleBarPure
        style={{
          height: layoutScroll ? `calc(100vh - ${height}px)` : "100%",
          width: "100%",
        }}
      >
        {children}
      </SimpleBarPure>
    </div>
  );
};

export default CustomScroll;
