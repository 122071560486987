import { ActionButtonData, PrefetchData, PrefetchVehicleDataInputFormat } from "../types/site";
import { Vehicle } from "../types/vehicle";

export function processActionButtonVariables(
  variableString: string,
  actionButtonData?: any,
  vehicle?: Vehicle,
): string {
  if (!actionButtonData) {
    return variableString;
  }

  const params: any = { ...actionButtonData };
  if (vehicle) {
    params.vehicle_vin = vehicle.vin;
    params.vehicle_stock = vehicle.stock;
    params.vehicle_price = vehicle.price;
    params.vehicle_make = vehicle.make;
    params.vehicle_model = vehicle.model;
    params.vehicle_year = vehicle.year;
    params.vehicle_trim = vehicle.trim;
    params.vehicle_photo = vehicle.photos?.[0] || "";
    params.vehicle_photo_medium = vehicle.photosMedium?.[0] || "";
  }

  const keys = Object.keys(params);
  return keys.reduce((accum, item) => {
    return accum.replaceAll(`{${item}}`, params[item]?.toString() || "");
  }, variableString);
}

export function processActionButtonCssVariables(variableString: string, actionButtonData?: any): string {
  if (!actionButtonData) {
    return variableString;
  }

  const params: any = { ...actionButtonData };

  const keys = Object.keys(params);
  return keys.reduce((accum, item) => {
    return accum.replaceAll(`param(${item})`, params[item]?.toString() || "");
  }, variableString);
}

export async function createPrefetchData(
  prefetchData: PrefetchData,
  vehicles: any[],
  actionButtonData?: any,
): Promise<any[]> {
  let url = processActionButtonVariables(prefetchData.url, actionButtonData);
  let data;
  if (prefetchData.format === PrefetchVehicleDataInputFormat.CSV) {
    const vehicleParam = vehicles
      .map((vehicle) => {
        return prefetchData.fields.map((field) => vehicle[field]).join(prefetchData.fieldSeparator);
      })
      .join(prefetchData.vehicleSeparator);
    if (prefetchData.method === "GET") {
      url += vehicleParam;
    } else {
      data = vehicleParam;
    }
  } else if (prefetchData.format === PrefetchVehicleDataInputFormat.JSON) {
    const vehicleData = vehicles.map((vehicle) => {
      return prefetchData.fields.reduce((accum: any, field) => {
        accum[field] = vehicle[field];
        return accum;
      }, {});
    });
    data = JSON.stringify(vehicleData);
  }

  const headers: [string, string][] = prefetchData.headers.map((header) => [header.header, header.value]);
  if (prefetchData.method !== "GET") {
    headers.push([
      "Content-Type",
      prefetchData.format === PrefetchVehicleDataInputFormat.CSV ? "text/csv" : "application/json",
    ]);
  }

  const request = new Request(url, { body: data, method: prefetchData.method, headers });
  const response = await fetch(request);
  if (response.status === 200 || response.status === 201) {
    const responseText = await response.text();
    if (responseText !== "") {
      return JSON.parse(responseText);
    }
  }
  return vehicles.map(() => null);
}

export async function prefetchActionButtonData(vehicles: Vehicle[], actionButtons: ActionButtonData[]) {
  const prefetchedData = await Promise.all(
    actionButtons.map((item) => {
      if (item.prefetchData) {
        return createPrefetchData(item.prefetchData, vehicles, item.actionButtonData);
      }
      return null;
    }),
  );
  vehicles.forEach((vehicle) => {
    vehicle.actionButtonsData = [];
  });
  for (let i = 0; i < prefetchedData.length; i++) {
    vehicles.forEach((vehicle, index) => {
      if (prefetchedData[i]) {
        vehicle.actionButtonsData!.push(prefetchedData[i]![index]);
      } else {
        vehicle.actionButtonsData!.push(null);
      }
    });
  }
}
