import(/* webpackMode: "eager" */ "/home/runner/work/dealership-website/dealership-website/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dealership-website/dealership-website/src/ui/app/accessibility-button/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dealership-website/dealership-website/src/ui/app/custom-scroll/custom-scroll.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dealership-website/dealership-website/src/ui/app/footer/footer-languages.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dealership-website/dealership-website/src/ui/app/header/dropdown-secondary.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/dealership-website/dealership-website/src/ui/app/header/header-additional.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/dealership-website/dealership-website/src/ui/app/header/header-actions.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/dealership-website/dealership-website/src/app/(content)/layout.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/dealership-website/dealership-website/src/ui/app/footer/footer-additional.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/dealership-website/dealership-website/src/ui/app/footer/copyright.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/dealership-website/dealership-website/src/ui/app/footer/footer-social-language.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/dealership-website/dealership-website/src/ui/app/footer/footer-links.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/dealership-website/dealership-website/src/ui/app/header/header-compares.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dealership-website/dealership-website/src/ui/app/header/header-desktop-submenu-alternative.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dealership-website/dealership-website/src/ui/app/header/header-desktop-submenu.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/dealership-website/dealership-website/src/ui/app/header/header-favorites.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/dealership-website/dealership-website/src/ui/app/header/header-mobile.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/dealership-website/dealership-website/src/ui/app/header/header-mobile-center.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/dealership-website/dealership-website/src/ui/app/header/header-desktop.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dealership-website/dealership-website/src/ui/app/header/header-phones.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/dealership-website/dealership-website/src/ui/app/header/header-search-button.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/dealership-website/dealership-website/src/ui/app/header/mobile-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dealership-website/dealership-website/src/ui/app/popups/popups.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dealership-website/dealership-website/src/ui/app/set-locale/index.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/dealership-website/dealership-website/src/ui/common-components/is-device/is-device.module.scss");
