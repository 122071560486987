"use client";

import { FC } from "react";
import cx from "classnames";
import { languages } from "../../../utils/constants";
import { changeLocale } from "../../../utils/localeUtils";
import styles from "./footer-languages.module.scss";

interface IFooterLanguages {
  currentLanguge: string;
}

const FooterLanguages: FC<IFooterLanguages> = ({ currentLanguge }) => {
  return (
    <div className={styles.language}>
      {languages.map((item) => (
        <button
          aria-label={item.title}
          key={item.value}
          name={item.title}
          className={cx(styles.languageItem, {
            [styles.active]: currentLanguge === item.value,
          })}
          onClick={() => {
            changeLocale(item.value);
          }}
        >
          {item.title}
        </button>
      ))}
    </div>
  );
};

export default FooterLanguages;
