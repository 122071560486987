import { FC } from "react";

interface IIframe {
  url: string;
  allowScrolling?: boolean;
  allowNavigation?: boolean;
  allowFullScreen?: boolean;
}

const Iframe: FC<IIframe> = ({ url, allowScrolling, allowNavigation, allowFullScreen }) => {
  const navigationSandbox = allowNavigation ? "allow-top-navigation-by-user-activation " : "";
  const sandbox = `${navigationSandbox}allow-top-navigation-by-user-activation allow-scripts allow-forms allow-same-origin allow-popups`;
  return (
    <iframe
      name={"iframe"}
      src={url}
      sandbox={sandbox}
      width={"100%"}
      height={"100%"}
      scrolling={allowScrolling ? "yes" : "no"}
      allowFullScreen={allowFullScreen}
      style={{
        border: "none",
        overflow: allowScrolling ? "auto" : "hidden",
      }}
    />
  );
};

export default Iframe;
