import { FC } from "react";
import styles from "./cookie.module.scss";
import Modal from "../../common-components/modal";
import { hidePopup } from "../../../hooks/usePopups";
import { Translations } from "../../../utils/translations";
import { getTranslationModule } from "../../../utils/translations";
import Button from "@/ui/common-components/button";

interface ICookie {
  isOpen: boolean;
  translations: Translations;
}

const cookieStyles = {
  overlay: {
    backgroundColor: "transparent !important",
    backdropFilter: "none",
    height: "fit-content",
    marginTop: "auto",
  },
};

const Cookie: FC<ICookie> = ({ isOpen, translations }) => {
  const t = getTranslationModule(translations, "common");

  const handleClose = (e: React.UIEvent) => {
    e.preventDefault();
    hidePopup();
  };

  const okHandler = (e: React.UIEvent) => {
    e.preventDefault();
    hidePopup();
    typeof window !== "undefined" && window.localStorage.setItem("cookie", "yes");
  };

  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      onClose={(e) => handleClose(e)}
      style={cookieStyles}
      hideCloseButton
      cookie
    >
      <section className={styles.container}>
        <p className={styles.text}>{t("cookie")}</p>
        <div className={styles.actions}>
          <Button
            aria-label={"accept cookies"}
            name={"cookieAcceptButton"}
            className={styles.button}
            onClick={(e) => okHandler(e)}
            text={t("ok")}
            type="primary"
          />
        </div>
      </section>
    </Modal>
  );
};

export default Cookie;
