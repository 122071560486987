"use client";

import { FC, useEffect, useState } from "react";
import cx from "classnames";
import { LogoPosition, MainMenu } from "../../../types/site";
import { RequestContext } from "../../../types/ui";
import { sendAnalyticsEvent } from "../../../utils/analitics";
import BurgerIcon from "../../icons/burger-icon";
import Menu from "../../common-components/menu";
import actionsStyles from "./header-actions.module.scss";
import { Translations } from "../../../utils/translations";

interface IMobileMenu {
  context: RequestContext;
  menu: MainMenu;
  logoPosition: LogoPosition;
  translations: Translations;
}

const MobileMenu: FC<IMobileMenu> = ({ context, menu, logoPosition, translations }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    sendAnalyticsEvent("asc_menu_interaction", {
      element_text: "<hamburger>",
      element_type: "header",
      event_action: "click",
      element_subtype: "hamburger",
    });
  }, [isOpen]);

  return (
    <>
      <button
        name={"menuMobileButton"}
        aria-label={"site menu/navigation"}
        className={cx(actionsStyles.actionsButton, actionsStyles.actionsItem, actionsStyles.siteNavigationButton)}
        onClick={() => setIsOpen(true)}
      >
        <BurgerIcon />
      </button>
      {isOpen && (
        <Menu
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          menu={menu}
          context={context}
          logoPosition={logoPosition}
          translations={translations}
        />
      )}
    </>
  );
};

export default MobileMenu;
