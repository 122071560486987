const ShowResultsIcon = () => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.49508 11.3545L5.25 14.8471M5.25 14.8471L8.49999 18.3604M5.25 14.8471L13.75 14.8471L13.75 4.36328"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);

export default ShowResultsIcon;
