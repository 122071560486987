"use client";

import { useEffect, useState, useCallback, FC, createRef } from "react";
import cx from "classnames";
import styles from "./global-search.module.scss";
import Modal from "../../common-components/modal";
import { IDealerInfo, Search, SearchCategory } from "../../../types/site";
import Button from "../../common-components/button";
import SearchIcon from "../../icons/search-icon";
import ShowResultsIcon from "../../icons/show-results-icon";
import { formatNumber } from "../../../utils/stringFormatter";
import { useIsMobile } from "../../../utils/useIsMobile";
import { getSearchResponse } from "../../../api/client-api";
import { Vehicle } from "../../../types/vehicle";
import { sendAnalyticsEvent } from "../../../utils/analitics";
import { RequestContext } from "../../../types/ui";
import { getVehicleUrl } from "../../../utils/ui";
import { hidePopup } from "../../../hooks/usePopups";
import { getTranslationModule, Translations } from "../../../utils/translations";

interface IGlobalSearch {
  isOpen: boolean;
  logoUrl: string;
  context: RequestContext;
  searchData: Search;
  siteId: string;
  dealer: IDealerInfo;
  translations: Translations;
}

const modalStyles = {
  overlay: {
    alignItems: "flex-start",
    backgroundColor: "rgba(24, 24, 24, 0.8)",
  },
  content: {
    maxWidth: "inherit",
    width: "100%",
  },
};

const GlobalSearch: FC<IGlobalSearch> = ({ isOpen, logoUrl, context, searchData, siteId, dealer, translations }) => {
  const isMobile = useIsMobile(600, context.isSmartphone);
  const isTablet = useIsMobile(1280, context.isSmartphone);
  const [searchValue, setSearchValue] = useState("");
  const [noResults, setNoResults] = useState(false);
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const inputRef = createRef() as any;
  const t = getTranslationModule(translations, "footer-header");
  const limit = 15;
  const dealerPhone = dealer.departments[0].phone || "";

  const onSearch = useCallback(
    (value: string) => {
      sendAnalyticsEvent("asc_cta_interaction", {
        element_text: value,
        element_type: "header",
        element_title: `search: ${value}`,
        event_action_result: "search",
        link_url: "",
      });
      getSearchResponse(siteId, value, limit).then((searchResult) => {
        setVehicles(searchResult?.vehicles || []);
        !searchResult?.vehicles.length ? setNoResults(true) : setNoResults(false);
      });
    },
    [siteId],
  );

  const onCategoryClick = useCallback((category: SearchCategory) => {
    sendAnalyticsEvent("asc_cta_interaction", {
      element_text: category.title,
      element_type: "header",
      eelement_title: `global_search_category: ${category.title}`,
      event_action_result: "search",
      link_url: category.path,
    });
    hidePopup();
  }, []);

  const onChange = useCallback(
    (e: any) => {
      setSearchValue(e.target.value);
      setNoResults(false);
    },
    [setSearchValue],
  );

  const onKeyDown = useCallback(
    (e: any) => {
      if (e.keyCode === 13) {
        if (onSearch) {
          onSearch(searchValue);
        }
      }
    },
    [onSearch, searchValue],
  );

  useEffect(() => {
    isOpen && inputRef && inputRef?.current?.focus();
  }, [isOpen, inputRef]);

  const searchItemClickHandler = (e: any, vehicle: Vehicle) => {
    if (!e.target.className.includes("callForPrice")) {
      window.open(getVehicleUrl(vehicle), "_self");
      hidePopup();
    }
  };
  const categories = searchData.popularCategories || [];

  const handleOnImageLoadError = (e: any) => {
    e.target.src = "/images/out-of-stock.png";
  };

  const onPhoneClick = useCallback(() => {
    sendAnalyticsEvent("asc_click_to_call", {
      comm_phone_number: dealerPhone,
      department: "sales",
    });
  }, [dealerPhone]);

  return (
    <Modal isOpen={isOpen} onClose={hidePopup} closeButtonClass={styles.closeButton} style={modalStyles}>
      <section className={styles.container}>
        {!isTablet && (
          <a className={styles.logo} onClick={hidePopup} href={"/"}>
            {logoUrl && <img src={logoUrl} alt="logo" />}
          </a>
        )}
        <div className={styles.searchContainer}>
          <div className={styles.inputContainer}>
            <SearchIcon className={styles.searchIcon} />
            <input
              type="text"
              name="search"
              className={styles.input}
              value={searchValue}
              onChange={onChange}
              onKeyDown={onKeyDown}
              placeholder={`${t("model")}/${t("make")}/${t("vin")}/${t("stock")}`}
              ref={inputRef}
            />
          </div>
          <Button
            name={"showSearchResults"}
            ariaLabel={t("show_results")}
            type="primary"
            text={isMobile ? "" : t("show_results")}
            onClick={() => {
              if (onSearch) {
                onSearch(searchValue);
              }
            }}
            icon={<ShowResultsIcon />}
            className={styles.showResultsButton}
          />
        </div>
        <div className={styles.suggestions}>
          {categories.length && (
            <div className={styles.content}>
              <h4 className={styles.category}>{t("popular_categories")}</h4>
              <ul className={styles.list}>
                {categories.map((category) => (
                  <li key={category.path}>
                    <a href={category.path} className={styles.link} onClick={() => onCategoryClick(category)}>
                      {category.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {vehicles?.length > 0 && (
            <div className={styles.content}>
              <h4 className={styles.category}>{t("vehicles")}</h4>
              <ul className={styles.list}>
                {vehicles?.map((vehicle, index) => (
                  <li className={styles.vehicle} onClick={(e) => searchItemClickHandler(e, vehicle)} key={index}>
                    <img
                      className={styles.vehicleImage}
                      src={vehicle.photos[0] || "/images/out-of-stock.png"}
                      alt=""
                      onError={handleOnImageLoadError}
                    />
                    <div className={styles.vehicleContainer}>
                      <div className={styles.vehicleInfo}>
                        <h5
                          className={cx(styles.vehicleTitle, styles.vehicleText)}
                          title={vehicle.year + " " + vehicle.make + " " + vehicle.model}
                        >
                          {vehicle.year + " " + vehicle.make + " " + vehicle.model}
                        </h5>
                        {vehicle.price > 0 ? (
                          <span className={cx(styles.vehiclePrice, styles.vehicleText)}>{`$ ${formatNumber(
                            vehicle.price,
                          )}`}</span>
                        ) : (
                          <a
                            title={t("call_for_price")}
                            href={`tel:${dealerPhone}`}
                            className={styles.callForPrice}
                            onClick={onPhoneClick}
                          >
                            {t("call_for_price")}
                          </a>
                        )}
                      </div>
                      <div className={styles.vehicleInfo}>
                        <span className={cx(styles.vehicleType, styles.vehicleText)}>{vehicle.type}</span>
                        <span className={styles.vehicleDot}></span>
                        <span className={cx(styles.vehicleMileage, styles.vehicleText)}>{`${formatNumber(
                          vehicle.mileage || 0,
                        )} miles`}</span>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {noResults && (
            <div className={styles.content}>
              <h4 className={styles.category}>{`${t("no_results_search")} “${searchValue}” ${t("in_stock")}`}</h4>
              <h5 className={styles.subtitle}>{`${t("looking_for")}:`}</h5>
              <ul className={styles.list}>
                <li>
                  <a href="/search-result-page/used-vehicles" className={styles.link} onClick={hidePopup}>
                    {t("used_vehicles")}
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
      </section>
    </Modal>
  );
};

export default GlobalSearch;
