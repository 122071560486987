"use client";

import styles from "./styles.module.scss";
import AccessibilityIcon from "../../icons/accessibility";
import { handleButtonAction } from "../../../utils/ui";
import { RouteType } from "../../../types/site";
import { FC, useCallback, useEffect } from "react";
import { sendAnalyticsEvent } from "../../../utils/analitics";
import applyAccessibilityWidget from "../../../utils/applyAccessibilityWidget";

interface IAccessibilityButton {}

const AccessibilityButton: FC<IAccessibilityButton> = () => {
  useEffect(() => {
    applyAccessibilityWidget();
  }, []);

  const buttonHandler = useCallback(() => {
    const buttonAction = {
      path: "/popup/accessibility",
      action: "",
      routeType: RouteType.POPUP,
      width: 500,
      height: 530,
    };
    handleButtonAction(buttonAction);
    sendAnalyticsEvent("asc_cta_interaction", {
      element_text: "accessibility widget button",
      element_type: "accessibility_tool",
      element_title: "",
      event_action_result: "popup",
      link_url: buttonAction.path,
    });
  }, []);

  return (
    <button
      aria-label={"accessibility widget button"}
      name={"accessibilityButton"}
      className={styles.button}
      onClick={buttonHandler}
    >
      <AccessibilityIcon />
    </button>
  );
};

export default AccessibilityButton;
