import { FC } from "react";

interface ISearchIcon {
  className?: string;
}

const SearchIcon: FC<ISearchIcon> = ({ className }) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle r="6.74825" transform="matrix(-1 0 0 1 8.99394 9.00118)" stroke="currentColor" strokeWidth="1.5" />
    <path d="M13.6582 13.8633L18.2546 18.4597" stroke="currentColor" strokeWidth="1.5" />
  </svg>
);

export default SearchIcon;
