const CompareIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="2.91303" y1="18.0263" x2="18.4694" y2="2.46996" stroke="currentColor" strokeWidth="1.5" />
    <path
      d="M6.10924 8.93319H7.54155L4.83262 2H4.30329L1.58398 8.93319H3.01629L3.3588 8.01984H5.76673L6.10924 8.93319ZM5.36195 6.90928H3.76358L4.56277 4.56362L5.36195 6.90928Z"
      fill="currentColor"
    />
    <path
      d="M18.5571 16.4873C18.5571 15.6881 18.1419 15.0965 17.4362 14.8059C17.7994 14.4841 17.9759 14.0482 17.9759 13.5189C17.9759 12.3979 17.0937 11.6299 15.8482 11.6299H13.6582V18.48H16.3671C17.6645 18.48 18.5571 17.6497 18.5571 16.4873ZM15.7651 12.7923C16.336 12.7923 16.637 13.0933 16.637 13.5915C16.637 14.1001 16.336 14.4011 15.7651 14.4011H14.966V12.7923H15.7651ZM17.2182 16.425C17.2182 16.9958 16.8757 17.3176 16.253 17.3176H14.966V15.522H16.253C16.8757 15.522 17.2182 15.8541 17.2182 16.425Z"
      fill="currentColor"
    />
  </svg>
);

export default CompareIcon;
