"use client";

import cx from "classnames";
import CompareIcon from "../../icons/compare-icon";
import { useCompares } from "../../../hooks/useCompares";
import styles from "./header-compare.module.scss";
import { Translations } from "../../../utils/translations";
import { getLocaleText } from "../../../utils/translations";

interface IHeaderCompares {
  className: string;
  translations: Translations;
}

export default function HeaderCompares({ className, translations }: IHeaderCompares) {
  const title = getLocaleText(translations, "header-actions", "compares");
  const compareCount = useCompares();
  return (
    <a href={"/compares"} title={`${title} (${compareCount})`} className={cx(className, styles.compare)}>
      <CompareIcon />
      {compareCount > 0 && <span className={styles.compareCount}>{compareCount}</span>}
    </a>
  );
}
