export const CLOSE_POPUP_EVENT = "RML_CLOSE_POPUP";
export const SET_POPUP_RESULT_EVENT = "SET_POPUP_RESULT";

export function handlePopupClose() {
  window.parent.postMessage({ type: CLOSE_POPUP_EVENT }, "*");
}

export function setPopupResult(data: any) {
  window.parent.postMessage({ type: SET_POPUP_RESULT_EVENT, data }, "*");
}
