"use client";

import { useState, useEffect, useCallback, useMemo } from "react";

export const useViewport = () => {
  const [width, setWidth] = useState(0);

  const calculateWindowWidth = useCallback(() => setWidth(window.innerWidth), []);

  useEffect(() => {
    if (typeof window !== "undefined" && width === 0) {
      calculateWindowWidth();
    }
    window.addEventListener("resize", calculateWindowWidth);
    return () => window.removeEventListener("resize", calculateWindowWidth);
  }, [calculateWindowWidth, width]);

  return { width };
};

export function useIsMobile(width?: number, initialValue?: boolean): boolean {
  const [isMobile, setIsMobile] = useState(initialValue);

  const detectScreenWidth = useCallback(() => {
    if (typeof window !== "undefined") {
      if (window.innerWidth < (width || 1280)) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
  }, [width]);

  useEffect(() => {
    detectScreenWidth();

    if (typeof window !== "undefined") {
      window.addEventListener("orientationchange", detectScreenWidth);
      window.addEventListener("resize", detectScreenWidth);

      return () => {
        window.removeEventListener("resize", detectScreenWidth);
        window.removeEventListener("orientationchange", detectScreenWidth);
      };
    }
  }, [detectScreenWidth]);

  return isMobile || false;
}

export function useMobileView(isSmartphone: boolean) {
  const { width } = useViewport();
  return useMemo(() => {
    if (width > 0 && width < 1024 && isSmartphone) {
      return true;
    }
    if (width === 0) {
      return isSmartphone;
    }
    return false;
  }, [isSmartphone, width]);
}
